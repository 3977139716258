import { createAction, props } from '@ngrx/store';
import { Configurations, CountryConfigurations } from '@core/models/cms.model';

const ActionTypes = {
  FETCH_CONFIGURATIONS: '[Config] fetch app configurations',
  SET_CONFIGURATIONS: '[Config] set app configurations',
  FETCH_COUNTRY_CONFIGURATIONS: '[Config] fetch country configurations',
  SET_COUNTRY_CONFIGURATIONS: '[Config] set country configurations',
};

export const fetchConfigurations = createAction(
  ActionTypes.FETCH_CONFIGURATIONS,
);

export const setConfigurations = createAction(
  ActionTypes.SET_CONFIGURATIONS,
  props<{ configurations: Configurations }>()
);

export const fetchCountryConfigurations = createAction(
  ActionTypes.FETCH_COUNTRY_CONFIGURATIONS,
);

export const setCountryConfigurations = createAction(
  ActionTypes.SET_COUNTRY_CONFIGURATIONS,
  props<{ countryConfigurations: CountryConfigurations[] }>()
);