@let collapseMenu = collapseMenu$ | async;

<ion-app>
  <!-- Tablet and desktop structure -->
  @if (isTabletOrBigger) {
    @defer (when isTabletOrBigger) {
      <ion-split-pane when="xs" contentId="app-main-content">
        @if (shouldShowMenu) {
          <ion-menu class="menu" [ngClass]="{ 'menu--collapsed': collapseMenu }" contentId="app-main-content">
            <gd-menu [collapseMenu]="collapseMenu"></gd-menu>
          </ion-menu>
        }
        <ion-router-outlet id="app-main-content" mode="md"></ion-router-outlet>
      </ion-split-pane>
    }
  } @else {
    <!-- Mobile structure -->
    <ion-router-outlet id="app-main-content"></ion-router-outlet>
  }

  <!-- Core componentes -->
  <ng-container [ngTemplateOutlet]="coreComponentsTemplate"></ng-container>

  <!-- Dialog injection -->
  <section #dialogInjection></section>
</ion-app>

<!-- TEMPLATES -->

<!-- Core components template -->
<ng-template #coreComponentsTemplate>
  @defer (on idle) {
    <!-- Global loading -->
    @if ((showLoading$ | async) === true) {
      <gd-loading></gd-loading>
    }
  
    <!-- File upload progress -->
    @if (fileUploadProgress$ | async; as fileUploadProgress) {
      <gd-file-upload-progress [fileUploadProgress]="fileUploadProgress"></gd-file-upload-progress>
    }
  }
</ng-template>