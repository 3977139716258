import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, tap } from 'rxjs/operators';
import { ApiCmsService } from '@core/services-api/cms/api-cms.service';
import { AppService } from '@core/services/app/app.service';
import { fetchConfigurations, fetchCountryConfigurations, setConfigurations, setCountryConfigurations } from '@store/actions/cms.actions';


@Injectable()
export class CmsEffects {

  /**
   * Fetch app configurations
   */
  fetchConfigurations$ = createEffect(() => this.actions$.pipe(
    ofType(fetchConfigurations),
    switchMap(() => this.apiCmsService.fetchConfigurations().pipe(
      tap(({ configurations }) => this.appService.checkUpgradeAppAlert(configurations)),
      map(({ configurations }) => {
        return setConfigurations({ configurations });
      }),
    )),
  ));

  /**
   * Fetch country configurations
   */
  fetchCountryConfigurations$ = createEffect(() => this.actions$.pipe(
    ofType(fetchCountryConfigurations),
    switchMap(() => this.apiCmsService.fetchCountryConfigurations().pipe(
      map(({ countryConfigurations }) => {
        return setCountryConfigurations({ countryConfigurations });
      }),
    )),
  ));

  constructor(
    private actions$: Actions,
    private apiCmsService: ApiCmsService,
    private appService: AppService,
  ) {}

}
