import { NgZone, Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { Store } from '@ngrx/store';
import { PushNotificationData, PushNotificationType } from '@core/models/push-notification.model';
import { fetchUser } from '@store/actions/user.actions';
import { StoreState } from '@store/state/store.state';
import { LS_KEYS, StorageService } from '@core/services/storage/storage.service';
import { ApiPushNotificationsService } from '@core/services-api/push-notifications/api-push-notifications.service';

@Injectable({ providedIn: 'root' })
export class PushNotificationsService {

  /** Dependencies */
  private store = inject(Store<StoreState>);
  private ngZone = inject(NgZone);
  private storageService = inject(StorageService);
  private router = inject(Router);
  private apiPushNotificationsService = inject(ApiPushNotificationsService);

  /**
   * Register push notifications
   */
  public async initialize() {
    if (!Capacitor.isNativePlatform()) { return; }

    // Set notifications listeners
    await this.addPushNotificationListeners();
    await this.requestNotificationsPermissionsAndRegister();
  }

  /**
   * Request notifications permissions
   */
  public async requestNotificationsPermissionsAndRegister() {
    if (!Capacitor.isNativePlatform()) { return; }

    // Request device permissions
    const { receive } = await PushNotifications.requestPermissions();
    if (receive === 'granted') {
      await PushNotifications.register();
    } else {
      console.warn('[Notifications] No permissions granted');
    }
  }

  /**
   * Set all push notifications listeners
   */
  private async addPushNotificationListeners() {
    // Remove all listeners
    await PushNotifications.removeAllListeners();

    // Register push notifications
    await PushNotifications.addListener('registration', (token: Token) => {
      // Save FCM device token into device storage
      this.storageService.setItem(LS_KEYS.FCM_DEVICE_TOKEN, token.value);
      // Register device FCM token into user
      this.apiPushNotificationsService.registerFCMToken(token.value).subscribe();
    });

    // Listen to registration error
    await PushNotifications.addListener('registrationError', (error) => {
      console.warn('[Notifications] Error: ' + JSON.stringify(error));
    });

    // Listen to push notification received
    await PushNotifications.addListener('pushNotificationReceived', async (notification: PushNotificationSchema) => {
      console.info('Push received: ' + JSON.stringify(notification));
    });

    // Listen to push notification action performed
    await PushNotifications.addListener('pushNotificationActionPerformed', (notification: ActionPerformed) => {
      const data: PushNotificationData = notification.notification.data;
      this.ngZone.run(() => {
        this.navigateTo(data.notificationType);
        this.store.dispatch(fetchUser({}));
      });
      console.info('Action performed: ' + JSON.stringify(notification.notification.data));
    });
  }

  private navigateTo(notificationType: PushNotificationType) {
    switch (notificationType) {
    case 'verifyMaintenance':
      this.router.navigate(['/maintenances']);
      break;
    case 'transferVehicleRequest':
      this.router.navigate(['/vehicles']);
      break;
    case 'shareVehicleRequest':
      this.router.navigate(['/vehicles']);
      break;
    default:
      this.router.navigate(['/vehicles']);
      break;
    }
  }

}
