import { DatePipe, IMAGE_CONFIG, UpperCasePipe, registerLocaleData } from '@angular/common';
import { LOCALE_ID, NgModule } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { DateCustomPipe } from '@shared/pipes/date-custom.pipe';
import { RouteReuseStrategy } from '@angular/router';
import { IonicRouteStrategy } from '@ionic/angular/standalone';
import { SanitizePipe } from '@shared/pipes/sanitize.pipe';
import { ClearFilenamePipe } from '@shared/pipes/clear-filename.pipe';
import { NumberCustomPipe } from '@shared/pipes/number-custom.pipe';
import { TextCustomPipe } from '@shared/pipes/text-custom.pipe';
import { PhoneNumberPipe } from '@shared/pipes/phone-number.pipe';
import localeFr from '@angular/common/locales/fr';

registerLocaleData(localeFr, 'fr-FR');

@NgModule({
  providers: [
    { provide: 'Document', useValue: typeof document !== 'undefined' && document },
    { provide: 'Window', useValue: typeof window !== 'undefined' && window },
    { provide: 'Navigator', useValue: typeof navigator !== 'undefined' && navigator },
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    { provide: IMAGE_CONFIG, useValue: { disableImageSizeWarning: true, disableImageLazyLoadWarning: true } },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    TranslatePipe,
    UpperCasePipe,
    DatePipe,
    DateCustomPipe,
    NumberCustomPipe,
    TextCustomPipe,
    SanitizePipe,
    ClearFilenamePipe,
    PhoneNumberPipe,
  ],

})
export class CoreModule { }
