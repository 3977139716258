import { inject, Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { CancelOptions, LocalNotifications, LocalNotificationSchema, ScheduleOptions } from '@capacitor/local-notifications';
import { User } from '@core/models/user.model';
import { Appointment } from '@core/models/schedule.model';
import { LocalNotificationTypes } from '@core/models/notification.model';
import { generateLocalNotificationId, ONE_DAY_IN_MS } from '@helpers/common.helpers';
import { Maintenance, MaintenanceVehicleSummary } from '@core/models/maintenance.model';
import { Owner, Vehicle } from '@core/models/vehicle.model';
import { TranslateService } from '@ngx-translate/core';

const LOCAL_NOTIFICATION_ICON_PATH = 'res://notification_icon.png';

@Injectable({ providedIn: 'root' })
export class LocalNotificationsService {

  /** Dependencies */
  private translateService = inject(TranslateService);

  /**
   * Register user local notifications
   */
  public registerUserLocalNotifications(user: User, appointments: Appointment[]) {
    if (!Capacitor.isNativePlatform()) { return; }

    // Cancel all notifications
    this.cancelAllNotifications();
    // Vehicles notifications
    this.scheduleVehicleNotifications(user);
    // Appointments notifications
    this.scheduleAppointmentNotifications(appointments);
  }

  /**
   * Schedule appointment local notifications
   */
  public async scheduleAppointmentNotifications(appointments: Appointment[], shouldCancelNotifications?: boolean) {
    if (!Capacitor.isNativePlatform()) { return; }

    // Cancel all appointment alert date notifications
    if (shouldCancelNotifications) {
      const pending = await LocalNotifications.getPending();
      const notificationsToCancel = pending.notifications.filter(not => not.extra.type === LocalNotificationTypes.APPOINTMENT_ALERT_DATE);
      this.cancelAllNotifications(notificationsToCancel);
    }

    const appointmentsNotifications: LocalNotificationSchema[] = this.createAppointmentNotifications(appointments);
    this.scheduleLocalNotifications(appointmentsNotifications);
  }

  /**
   * Cancel all local notifications. If notifications are provided cancel only this
   */
  public async cancelAllNotifications(notifications?: LocalNotificationSchema[]) {
    if (!Capacitor.isNativePlatform()) { return; }

    const notificationIdsToCancel = notifications?.map(n => n.id);
    const pendingNotifications = await LocalNotifications.getPending();
    pendingNotifications.notifications.forEach(n => {
      // If there are notifications to cancel and the current pending notification is not included return
      if (notificationIdsToCancel && !notificationIdsToCancel.includes(n.id)) { return; }

      // Cancel
      const notification: CancelOptions = { notifications: [{ id: n.id }] };
      LocalNotifications.cancel(notification);
    });
  }

  /**
   * Schedule vehicle notifications
   */
  private scheduleVehicleNotifications(user: User) {
    if (!Capacitor.isNativePlatform()) { return; }

    const ownVehicles = user?.ownVehicles || [];
    const sharedVehicles = user?.sharedVehicles || [];
    const vehicles = [...ownVehicles, ...sharedVehicles];
    vehicles?.forEach(vehicle => {
      const itvNotifications: LocalNotificationSchema[] = this.createItvNotifications(vehicle, user, vehicle.owner);
      const insuranceNotifications: LocalNotificationSchema[] = this.createInsuranceNotifications(vehicle, user, vehicle.owner);
      const maintenancesNotifications: LocalNotificationSchema[] = [];
  
      vehicle.maintenances.forEach(maintenance => {
        const notif = this.createVehicleMaintenancesDateNotification(maintenance, vehicle, user, vehicle.owner);
        if (notif) { maintenancesNotifications?.push(notif); }
      });
  
      const notifications: LocalNotificationSchema[] = [...itvNotifications, ...insuranceNotifications, ...maintenancesNotifications];
      if (notifications) {
        this.scheduleLocalNotifications(notifications);
      }
    });
  }

  /**
   * Schedule local notificacions
   */
  private scheduleLocalNotifications(notifications: LocalNotificationSchema[]) {
    if (!Capacitor.isNativePlatform()) { return; }

    const validNotifications = this.filterValidNotifications(notifications);
    validNotifications.forEach(notif => {
      const notificationToCancel: CancelOptions = { notifications: [{ id: notif.id }] };
      LocalNotifications.cancel(notificationToCancel);
      const notificationsToSchedule: ScheduleOptions = { notifications: [notif] };
      LocalNotifications.schedule(notificationsToSchedule);
    });
  }

  /**
   * Filter notifications
   */
  private filterValidNotifications(notifications: LocalNotificationSchema[]): LocalNotificationSchema[] {
    if (!notifications) { return []; }
    return notifications.filter(notification => {
      // Filter only notifications after current date
      return notification.schedule?.at.getTime() > new Date().getTime();
    });
  }

  /**
   * Send local notifications when itv will expire soon and when is expired
   */
  private createVehicleMaintenancesDateNotification(
    payload: Maintenance, vehicle: MaintenanceVehicleSummary, user: User, owner: Owner
  ): LocalNotificationSchema {
    if (!Capacitor.isNativePlatform() || !payload.date || payload.done) { return undefined; }

    // If user is the owner create
    if (owner?.id === user.id) {
      return this.calculateLocalNotification(
        LocalNotificationTypes.MAINTENANCE_DATE,
        `${vehicle.brand} ${vehicle.vehicleModel}`,
        this.translateService.instant('NOTIFICATIONS.NEAR-MAINTENANCE', {title: payload.title}),
        new Date(new Date(payload.date).getTime() - ONE_DAY_IN_MS * 7),
      );
    }

    return null;
  }

  /**
   * Return local notifications when itv will expire soon and when is expired
   */
  private createItvNotifications(payload: Vehicle, user: User, owner: Owner): LocalNotificationSchema[] {
    if (!Capacitor.isNativePlatform()) { return []; }

    if (!payload.itvExpires || (user?.accountData?.userType === 'workshop' && user.id !== owner.id)) { return []; }

    // Workshop client vehicles itv notification
    if (user?.accountData?.userType === 'workshop' && owner && owner.id !== user.id) {
      return [
        this.calculateLocalNotification(
          LocalNotificationTypes.ITV_NEAR_EXPIRES,
          `${payload.brand} ${payload.vehicleModel}`,
          this.translateService.instant(
            'NOTIFICATIONS.WORKSHOP-ITV-NEAR-EXPIRATION-VEHICLE',
            { vehicle: `${payload.brand} ${payload.vehicleModel}`, client: `${owner?.name} ${owner?.lastName}` }
          ),
          new Date(new Date(payload.itvExpires).getTime() - ONE_DAY_IN_MS * 15)
        ),
      ];
    } else if (owner?.id === user.id) {
      // Client notification
      return [
        this.calculateLocalNotification(
          LocalNotificationTypes.ITV_NEAR_EXPIRES,
          `${payload.brand} ${payload.vehicleModel}`,
          this.translateService.instant(
            'NOTIFICATIONS.ITV-NEAR-EXPIRATION-VEHICLE', {vehicle: `${payload.brand} ${payload.vehicleModel}`}
          ),
          new Date(new Date(payload.itvExpires).getTime() - ONE_DAY_IN_MS * 15)
        ),
        this.calculateLocalNotification(
          LocalNotificationTypes.ITV_EXPIRED,
          `${payload.brand} ${payload.vehicleModel}`,
          this.translateService.instant(
            'NOTIFICATIONS.ITV-EXPIRED-VEHICLE', {vehicle: `${payload.brand} ${payload.vehicleModel}`}
          ),
          new Date(payload.itvExpires)
        ),
      ];
    } else {
      return [];
    }
  }

  /**
   * Return local notifications when insurance will expire soon and when is expired
   */
  private createInsuranceNotifications(payload: Vehicle, user: User, owner: Owner): LocalNotificationSchema[] {
    if (!Capacitor.isNativePlatform()) { return []; }

    if (!payload.itvExpires || (user?.accountData?.userType === 'workshop' && user.id !== owner.id)) { return []; }

    // Client notification
    return [
      this.calculateLocalNotification(
        LocalNotificationTypes.INSURANCE_NEAR_EXPIRES,
        `${payload.brand} ${payload.vehicleModel}`,
        this.translateService.instant(
          'NOTIFICATIONS.INSURANCE-NEAR-EXPIRATION-VEHICLE', {vehicle: `${payload.brand} ${payload.vehicleModel}`}
        ),
        new Date(new Date(payload.itvExpires).getTime() - ONE_DAY_IN_MS * 30),
      ),
      this.calculateLocalNotification(
        LocalNotificationTypes.INSURANCE_EXPIRED,
        `${payload.brand} ${payload.vehicleModel}`,
        this.translateService.instant(
          'NOTIFICATIONS.INSURANCE-EXPIRED-VEHICLE', {vehicle: `${payload.brand} ${payload.vehicleModel}`}
        ),
        new Date(payload.insuranceExpires)
      ),
    ];
  }

  /**
   * Send local notifications when insurance will expire soon and when is expired
   */
  private createAppointmentNotifications(appointments: Appointment[]): LocalNotificationSchema[] {
    if (!appointments.length) { return []; }

    return appointments
      .filter(appointment => appointment.alertDate)
      .map(appointment => {
        return this.calculateLocalNotification(
          LocalNotificationTypes.APPOINTMENT_ALERT_DATE,
          appointment.title,
          appointment.description,
          new Date(appointment.alertDate)
        );
      });
  }

  /**
   * Calculate local notification
   */
  private calculateLocalNotification(type: LocalNotificationTypes, title: string, body: string, schedule: Date): LocalNotificationSchema {
    return {
      id: generateLocalNotificationId(type),
      title: title || 'Guantera Digital',
      body: body || '',
      schedule: { at: schedule },
      smallIcon: LOCAL_NOTIFICATION_ICON_PATH,
      extra: { type },
    } as LocalNotificationSchema;
  }

}
