import { Action } from '@ngrx/store';
import { LS_KEYS, StorageService } from '@core/services/storage/storage.service';
import { deleteAccountConfirm, logout } from '@store/actions/user.actions';
import { storeInitialState, StoreState } from '@store/state/store.state';
import { CustomWindow } from 'custom-window';

let isRehydrated = false;

export const storageMetaReducer = (storageService: StorageService, customWindow: CustomWindow) => reducer => (state: StoreState, action: Action) => {
  // On first action, set initial state
  if (!isRehydrated) {
    // Set isRehydrated to true if action is not an ngrx action
    isRehydrated = !action.type.includes('@ngrx');

    const initialState: StoreState = getInitialState(storageService, customWindow);
    saveState(initialState, storageService, customWindow);
    return initialState;
  }

  // If action is logout, reset store
  if (action.type === logout.type || action.type === deleteAccountConfirm.type) {
    const resetState: StoreState = {
      ...storeInitialState,
      app: { ...storeInitialState.app, isDarkModeEnabled: state.app?.isDarkModeEnabled },
      cms: state.cms,
    };
    const newState: StoreState = reducer(resetState, action);
    saveState(newState, storageService, customWindow);
    return newState;
  }

  // Update the store on each action if user exists
  const newState: StoreState = reducer(state, action);
  storageService.setItem(LS_KEYS.STORE, JSON.stringify(newState));
  saveState(newState, storageService, customWindow);
  return newState;
};

/**
 * Get the initial state from the storage
 */
const getInitialState = (storageService: StorageService, customWindow: CustomWindow) => {
  let state: StoreState = storeInitialState;
  // If we have store on storage we get it
  const savedStore: StoreState = JSON.parse(storageService.getItem(LS_KEYS.STORE)) || {};
  if (savedStore) {
    state = {
      app: { ...state.app, ...savedStore.app, showLoading: false },
      cms: { ...state.cms },
      user: { ...state.user, ...savedStore.user },
      vehicles: { ...state.vehicles, ...savedStore.vehicles },
      checkout: { ...state.checkout, ...savedStore.checkout },
      schedule: { ...state.schedule, ...savedStore.schedule },
    };
  }

  storageService.setItem(LS_KEYS.STORE, JSON.stringify(state));
  saveState(state, storageService, customWindow);

  return state;
};

/**
 * Save the state in the storage and in the global window
 */
const saveState = (state: StoreState, storageService: StorageService, customWindow: CustomWindow) => {
  storageService.setItem(LS_KEYS.STORE, JSON.stringify(state));
  customWindow.gdState = state;
};
