import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, tap, map, filter, catchError } from 'rxjs/operators';
import {
  activateAccount,
  deleteAccountCancel,
  deleteAccountConfirm,
  deleteAccountRequest,
  fetchUser,
  socialAuth,
  login,
  logout,
  logoutOnAllDevices,
  signup,
  updateUser,
} from '@store/actions/user.actions';
import { ToastService } from '@core/services/presentable-services/toast/toast.service';
import { StoreService } from '@store/services/store.service';
import { LS_KEYS, StorageService } from '@core/services/storage/storage.service';
import { Router } from '@angular/router';
import { PushNotificationsService } from '@core/services/plugin-services/push-notifications/push-notifications.service';
import { LoginApiResponse } from '@core/models-api/user-api.model';
import { StoreState } from '@store/state/store.state';
import { Store } from '@ngrx/store';
import { ApiUserService } from '@core/services-api/user/api-user.service';
import { LocalNotificationsService } from '@core/services/plugin-services/local-notifications/local-notifications.service';
import { EMPTY, of, throwError } from 'rxjs';
import { UserService } from '@pages/user/services/user.service';
import { emptyAction } from '@store/actions/app.actions';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { AdsService } from '@core/services/plugin-services/ads/ads.service';
import { CacheService } from '@core/services/cache/cache.service';

@Injectable()
export class UserEffects {

  /**
   * Fetch user data
   */
  signup$ = createEffect(() => this.actions$.pipe(
    ofType(signup),
    switchMap(({ signupForm }) => this.apiUserService.signUp(signupForm).pipe(
      tap((res: LoginApiResponse) => this.storageService.setItem(LS_KEYS.TOKEN, res.token)),
      tap(() => {
        this.toastService.presentSuccess('SUCCESS.SIGN-UP', 3000);
        this.onLoginSuccess();
      }),
      this.storeService.setCompleteStoreOperator(),
    )),
  ));

  /**
   * Fetch user data
   */
  login$ = createEffect(() => this.actions$.pipe(
    ofType(login),
    switchMap(({ loginForm }) => this.apiUserService.login(loginForm).pipe(
      tap((res: LoginApiResponse) => this.storageService.setItem(LS_KEYS.TOKEN, res.token)),
      tap(() => this.onLoginSuccess()),
      this.storeService.setCompleteStoreOperator(),
    )),
  ));

  /**
   * Social auth
   */
  socialAuth$ = createEffect(() => this.actions$.pipe(
    ofType(socialAuth),
    switchMap(({ socialAuthForm }) => this.apiUserService.socialAuth(socialAuthForm).pipe(
      // If is a Google sign up show welcome message
      tap((res: LoginApiResponse) => {
        if (res.isSocialSignup) {
          this.toastService.presentSuccess('SUCCESS.SOCIAL-SIGN-UP');
        }
      }),
      tap((res: LoginApiResponse) => this.storageService.setItem(LS_KEYS.TOKEN, res.token)),
      tap(() => this.onLoginSuccess()),
      tap(() => FirebaseAuthentication.signOut()),
      this.storeService.setCompleteStoreOperator(),
    )),
  ));

  /**
   * Logout
   */
  logout$ = createEffect(() => this.actions$.pipe(
    ofType(logout),
    switchMap(({ shouldLogoutOnApi = true }) => {
      if (shouldLogoutOnApi) {
        return this.apiUserService.logout(this.storageService.getItem(LS_KEYS.FCM_DEVICE_TOKEN)).pipe(
          catchError(e => {
            this.userService.removeTokensAndGoToLogin();
            return throwError(() => e);
          }),
          tap(() => this.onLogoutSuccess()),
        );
      } else {
        return of(EMPTY).pipe(tap(() => this.onLogoutSuccess()));
      }
    }),
  ), { dispatch: false });
 
  /**
   * Logout on all devices
   */
  logoutOnAllDevices$ = createEffect(() => this.actions$.pipe(
    ofType(logoutOnAllDevices),
    switchMap(() => this.apiUserService.logoutAllDevices().pipe(
      catchError(e => {
        this.userService.removeTokensAndGoToLogin();
        return throwError(() => e);
      }),
      tap(() => {
        this.localNotificationsService.cancelAllNotifications();
        this.userService.removeTokensAndGoToLogin();
      }),
    )),
  ), { dispatch: false });

  /**
   * Fetch user data
   */
  fetchUser$ = createEffect(() => this.actions$.pipe(
    ofType(fetchUser),
    switchMap(({ showLoading }) => this.apiUserService.fetchUser(showLoading).pipe(
      this.storeService.setCompleteStoreOperator(),
    )),
  ));

  /**
   * Update user data
   */
  updateUser$ = createEffect(() => this.actions$.pipe(
    ofType(updateUser),
    switchMap(({ user, showLoading }) => this.apiUserService.updateUser(user, showLoading).pipe(
      tap(() => showLoading && this.toastService.presentSuccess('SUCCESS.UPDATE-USER')),
      this.storeService.setCompleteStoreOperator(),
    )),
  ));

  /**
   * Confirm user account
   */
  activateAccount$ = createEffect(() => this.actions$.pipe(
    ofType(activateAccount),
    switchMap(({ accountActivationCode }) => this.apiUserService.activateAccount(accountActivationCode).pipe(
      tap(() => this.toastService.presentSuccess('SUCCESS.ACTIVATED-ACCOUNT')),
      filter(({ accountData }) => !accountData.isPremiumWithoutSubscription),
      this.storeService.setAccountDataStoreOperator(),
    )),
  ));

  /**
   * Delete user account request
   */
  deleteAccountRequest$ = createEffect(() => this.actions$.pipe(
    ofType(deleteAccountRequest),
    switchMap(() => this.apiUserService.deleteAccountRequest().pipe(
      tap(() => this.toastService.presentInfo('SUCCESS.DELETION-CONFIRMATION-CODE-SENT', 3000)),
      this.storeService.setAccountDataStoreOperator(),
    )),
  ));

  /**
   * Delete user account confirm
   */
  deleteAccountConfirm$ = createEffect(() => this.actions$.pipe(
    ofType(deleteAccountConfirm),
    switchMap(({ userId, confirmationCode }) => {
      return this.apiUserService.deleteAccountConfirm(userId, confirmationCode).pipe(
        tap(() => this.toastService.presentInfo('SUCCESS.DELETED-ACCOUNT')),
        tap(() => this.userService.removeTokensAndGoToLogin()),
        map(() => emptyAction())
      );
    }),
  ));

  /**
   * Delete user account cancel
   */
  deleteAccountCancel$ = createEffect(() => this.actions$.pipe(
    ofType(deleteAccountCancel),
    switchMap(() => this.apiUserService.deleteAccountCancel().pipe(
      this.storeService.setAccountDataStoreOperator(),
    )),
  ));

  constructor(
    private actions$: Actions,
    private store: Store<StoreState>,
    private storeService: StoreService,
    private userService: UserService,
    private apiUserService: ApiUserService,
    private toastService: ToastService,
    private storageService: StorageService,
    private router: Router,
    private pushNotificationsService: PushNotificationsService,
    private localNotificationsService: LocalNotificationsService,
    private adsService: AdsService,
    private cacheService: CacheService,
  ) {}

  /**
   * On login success
   */
  private onLoginSuccess() {
    // Navigate to clients (if is client the guard will redirect to vehicles)
    this.router.navigate(['/clients']);
    // Register push notifications associated with user
    this.pushNotificationsService.initialize();
  }

  /**
   * On logout success
   */
  private onLogoutSuccess() {
    // Cancel all local notifications
    this.localNotificationsService.cancelAllNotifications();
    // Remove user token and redirect to login
    this.userService.removeTokensAndGoToLogin();
    // Destroy ads
    this.adsService.destroyAds();
    // Clear cache
    this.cacheService.clear();
  }

}
