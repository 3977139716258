import { Component, ViewChild, ViewContainerRef, inject } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Store } from '@ngrx/store';
import { startWith, distinctUntilChanged, take } from 'rxjs/operators';
import { StoreState } from './store/state/store.state';
import { StatusBar, Style } from '@capacitor/status-bar';
import { IonApp, IonRouterOutlet, Platform, IonSplitPane, IonMenu } from '@ionic/angular/standalone';
import { selectDistinctUserId } from './store/selectors/user.selectors';
import { Observable } from 'rxjs';
import { selectMenuStatus, selectValidDarkModeChanged, selectValidLoadingChanged } from '@store/selectors/app.selectors';
import { FilesFolderService } from '@pages/files-folder/services/files-folder.service';
import { UploadFileProgress } from '@core/models/files-folder.model';
import { checkSubscriptionStatus } from '@store/actions/checkout.actions';
import { LoadingComponent } from '@core/components/loading/loading.component';
import { FileUploadProgressComponent } from '@core/components/file-upload-progress/file-upload-progress.component';
import { CommonModule, DOCUMENT } from '@angular/common';
import { WINDOW } from 'custom-window';
import { AppService } from '@core/services/app/app.service';
import { MenuComponent } from '@core/components/menu/menu.component';
import { calculateIsTabletOrBigger } from '@helpers/common.helpers';
import { selectValidConfigurations } from '@store/selectors/cms.selectors';
import { fetchCountryConfigurations } from '@store/actions/cms.actions';

@Component({
  selector: 'gd-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  imports: [
    CommonModule,
    IonApp,
    IonRouterOutlet,
    IonSplitPane,
    IonMenu,
    MenuComponent,
    LoadingComponent,
    FileUploadProgressComponent,
  ],
})
export class AppComponent {

  /** App view container ref */
  @ViewChild('dialogInjection', { read: ViewContainerRef }) dialogInjection: ViewContainerRef;

  /** Observable to indicate if show loading */
  public showLoading$: Observable<boolean>;
  /** Observable to indicate if collapse menu */
  public collapseMenu$: Observable<boolean>;
  /** File upload progress */
  public fileUploadProgress$: Observable<UploadFileProgress>;
  /** Indicates if screen is tablet or bigger size */
  public isTabletOrBigger: boolean;
  /** Indicates if should show menu */
  public shouldShowMenu: boolean;

  /** Dependencies */
  private platform = inject(Platform);
  private store = inject(Store<StoreState>);
  private filesService = inject(FilesFolderService);
  private appService = inject(AppService);
  protected document = inject(DOCUMENT);
  protected window = inject(WINDOW);

  constructor() {
    this.isTabletOrBigger = calculateIsTabletOrBigger(this.window.innerWidth, this.window.innerHeight);

    this.platform.ready().then(() => {
      // Subscriptions
      this.showLoading$ = this.store.pipe(selectValidLoadingChanged);
      this.collapseMenu$ = this.store.select(selectMenuStatus);
      this.fileUploadProgress$ = this.filesService.fileUploadProgress$;
      this.setUserSubscription();
      this.setConfigurationsSubscription();
      this.setDarkModeSubscription();
      this.setPdfViewerSubscription();
    });
  }

  /**
   * Fetch subscription, products and config if user is logged
   */
  private setUserSubscription() {
    this.store
      .pipe(selectDistinctUserId)
      .subscribe((user) => {
        // Set menu visibility
        this.shouldShowMenu = !!user?.id;

        // Check subscription status
        if (user?.accountData?.userType === 'workshop' || user?.accountData?.isFleet) {
          this.store.dispatch(checkSubscriptionStatus({ showLoading: false }));
        }
      });
  }

  /**
   * Set configurations subscription
   */
  private setConfigurationsSubscription() {
    this.store
      .pipe(
        selectValidConfigurations,
        take(1),
      )
      .subscribe(() => this.store.dispatch(fetchCountryConfigurations()));
  }

  /**
   * Set pdf viewer subscription
   */
  private setPdfViewerSubscription() {
    this.filesService.showPdfViewer$.subscribe(async ({ file, fileUrl, isLocal }) => {
      const { PdfViewerWrapperComponent } = await import('@shared/components/pdf-viewer-wrapper/pdf-viewer-wrapper.component');
      this.appService.emitVibration();
      const componentRef = this.dialogInjection.createComponent(PdfViewerWrapperComponent);
      componentRef.instance.file = file;
      componentRef.instance.fileUrl = fileUrl;
      componentRef.instance.isLocal = isLocal;
      componentRef.instance.closeViewer.subscribe(() => componentRef.destroy());
    });
  }

  /**
   * Check if dark mode is enabled and set
   */
  private setDarkModeSubscription() {
    this.store
      .pipe(
        selectValidDarkModeChanged,
        startWith(false),
        distinctUntilChanged(),
      )
      .subscribe(isDarkMode => {
        // Set dark mode class on body
        this.document.querySelector('html').setAttribute('theme', isDarkMode ? 'dark' : 'light');
        // Set status bar style on ios (device info)
        if (Capacitor.getPlatform() === 'ios') { StatusBar.setStyle({ style: isDarkMode ? Style.Dark : Style.Light }); }
        // Set status bar style on android (device info)
        if (Capacitor.getPlatform() === 'android') { StatusBar.setStyle({ style: Style.Default }); }
      });
  }

}
