import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import {
  createMaintenance,
  deleteVehicleMaintenance,
  updateMaintenance,
  updateMaintenanceStatus,
} from '../actions/maintenances.actions';
import { ApiMaintenancesService } from '@core/services-api/maintenances/api-maintenances.service';
import { ToastService } from '@core/services/presentable-services/toast/toast.service';
import { StoreService } from '@store/services/store.service';
import { Router } from '@angular/router';
import { StoreState } from '@store/state/store.state';
import { Store } from '@ngrx/store';
import { setWorkshopVerifiedMaintenance } from '@store/actions/workshop.actions';
import { MaintenanceApi } from '@core/models-api/maintenance-api.model';
import { MaintenancesTransformer } from '@core/transformers/maintenances.transformer';
import { selectValidUser } from '@store/selectors/user.selectors';
import { AdsService } from '@core/services/plugin-services/ads/ads.service';

@Injectable()
export class MaintenancesEffects {

  /**
   * Create maintenance
   */
  createMaintenance$ = createEffect(() => this.actions$.pipe(
    ofType(createMaintenance),
    switchMap(({ vehicleId, maintenance }) => this.apiMaintenancesService.createMaintenance(vehicleId, maintenance).pipe(
      tap(({ maintenanceId }) => {
        // Navigate to new maintenance detail
        if (maintenanceId) {
          this.router.navigateByUrl(`/detail-maintenance/${maintenanceId}`);
        } else {
          this.toastService.presentSuccess('SUCCESS.NEW-MAINTENANCE');
        }
      }),
      // Add one action to interstitial counter
      tap(() => this.adsService.setToInterstitialCounter()),
      this.storeService.setVehicleStoreOperator(),
    )),
  ));

  /**
   * Update maintenance
   */
  updateMaintenance$ = createEffect(() => this.actions$.pipe(
    ofType(updateMaintenance),
    switchMap(({ maintenanceId, maintenanceBody }) =>
      this.apiMaintenancesService.updateMaintenance(maintenanceId, maintenanceBody).pipe(
        withLatestFrom(this.store.pipe(selectValidUser)),
        // If maintenance is verified update from verified maintenances
        tap(([{ vehicle }, user]) => {
          if (maintenanceBody?.workshopData?.isMaintenanceVerified) {
            const maintenanceApi = (vehicle.maintenances as MaintenanceApi[])?.find(m => m._id === maintenanceId);
            const maintenance = MaintenancesTransformer.maintenanceFromApi(maintenanceApi, user);
            this.store.dispatch(setWorkshopVerifiedMaintenance({ maintenance }));
          }
        }),
        // Show success toast
        tap(() => this.toastService.presentSuccess('SUCCESS.UPDATE-MAINTENANCE')),
        // Add one action to interstitial counter
        tap(() => this.adsService.setToInterstitialCounter()),
        // Map to original response
        map(([res]) => res),
        this.storeService.setVehicleStoreOperator(),
      )),
  ));

  /**
   * Update maintenance status
   */
  updateMaintenanceStatus$ = createEffect(() => this.actions$.pipe(
    ofType(updateMaintenanceStatus),
    switchMap(({ maintenanceId, done }) =>
      this.apiMaintenancesService.updateMaintenanceStatus(maintenanceId, done).pipe(
        // Add one action to interstitial counter
        tap(() => this.adsService.setToInterstitialCounter()),
        this.storeService.setVehicleStoreOperator(),
      )),
  ));

  /**
   * Delete vehicle maintenance
   */
  deleteVehicleMaintenance$ = createEffect(() => this.actions$.pipe(
    ofType(deleteVehicleMaintenance),
    switchMap(({ vehicleId, maintenanceId }) => this.apiMaintenancesService.deleteVehicleMaintenance(vehicleId, maintenanceId).pipe(
      tap(() => this.toastService.presentSuccess('SUCCESS.DELETE-MAINTENANCE')),
      tap(() => this.router.navigateByUrl(`/detail-vehicle/${vehicleId}`, { replaceUrl: true })),
      this.storeService.setVehicleStoreOperator(),
    )),
  ));

  constructor(
    private actions$: Actions,
    private store: Store<StoreState>,
    private storeService: StoreService,
    private apiMaintenancesService: ApiMaintenancesService,
    private toastService: ToastService,
    private router: Router,
    private adsService: AdsService,
  ) {}

}
