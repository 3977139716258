import { ALL_ICONS } from '@assets/icons/app-icons';
import { Capacitor } from '@capacitor/core';
import { BrowserTypes } from '@core/models/app.model';
import { SUPPORTED_LANGUAGES } from '@core/models/user.model';
import { AdsService } from '@core/services/plugin-services/ads/ads.service';
import { LS_KEYS, StorageService } from '@core/services/storage/storage.service';
import { Store } from '@ngrx/store';
import { DEFAULT_LANGUAGE, TranslateService } from '@ngx-translate/core';
import { fetchConfigurations } from '@store/actions/cms.actions';
import { fetchUser } from '@store/actions/user.actions';
import { selectValidConfigurations } from '@store/selectors/cms.selectors';
import { selectValidDistinctLanguage } from '@store/selectors/user.selectors';
import { StoreState } from '@store/state/store.state';
import { CustomWindow } from 'custom-window';
import { NgcCookieConsentService, NgcStatusChangeEvent } from 'ngx-cookieconsent';
import { map, startWith, take } from 'rxjs';

export function appInitializer(
  store: Store<StoreState>,
  translateService: TranslateService,
  storageService: StorageService,
  cookiesService: NgcCookieConsentService,
  adsService: AdsService,
  window: CustomWindow,
) {
  return function() {
    // Platform actions
    doWebActions(window);
    doDeviceActions();

    // Initial actions
    fetchCmsConfigurations(store);
    fetchUserIfLogged(storageService, store);
    loadIcons();
    setCssFixes(store);
    setLanguage(store, translateService, storageService);
    initCookies(storageService, cookiesService, adsService);
  };
}

/**
 * Fetch user if has a valid token
 */
function fetchUserIfLogged(storageService: StorageService, store: Store<StoreState>) {
  const token = storageService.getItem(LS_KEYS.TOKEN);
  if (!token || token === 'undefined') { return; }
  store.dispatch(fetchUser({}));
}

function fetchCmsConfigurations(store: Store<StoreState>) {
  store.dispatch(fetchConfigurations());
}

/**
 * Load app icons
 */
async function loadIcons() {
  const { addIcons } = await import('ionicons');
  addIcons({ ...ALL_ICONS });
}

/**
 * Load css fixes
 */
function setCssFixes(store: Store<StoreState>) {
  store
    .pipe(
      selectValidConfigurations,
      take(1),
    )
    .subscribe(({ fixesCss }) => {
      const styleId = 'css-fixes';
      const styleElement = document.createElement('style');
      styleElement.setAttribute('id', styleId);
      styleElement.innerHTML = fixesCss;

      // Remove if exists
      const existingStyleElement = document.getElementById(styleId);
      if (existingStyleElement) {
        existingStyleElement.remove();
      }

      // Append element to head
      document.head.appendChild(styleElement);
    });
}

/**
 * Configure app language
 */
function setLanguage(store: Store<StoreState>, translateService: TranslateService, storageService: StorageService) {
  const browserLanguage = SUPPORTED_LANGUAGES.find(el => el === translateService.getBrowserLang()) || DEFAULT_LANGUAGE;
  store
    .pipe(
      selectValidDistinctLanguage,
      map(language => language || storageService.getItem(LS_KEYS.LANGUAGE) || browserLanguage),
      startWith(storageService.getItem(LS_KEYS.LANGUAGE) || browserLanguage),
    )
    .subscribe((language: string) => {
      translateService.setDefaultLang(language);
      translateService.use(language);
      storageService.setItem(LS_KEYS.LANGUAGE, language);
    });
}

/**
 * Init cookies service
 */
function initCookies(storageService: StorageService, cookiesService: NgcCookieConsentService, adsService: AdsService) {
  const hasCookiesAccepted = !!storageService.getItem(LS_KEYS.COOKIES_ACCEPT);
  if (hasCookiesAccepted) {
    // Initialize ads service if cookies has been accepted
    adsService.initialize();
    return;
  }

  cookiesService.open();
  cookiesService.statusChange$
    .pipe(take(1))
    .subscribe((event: NgcStatusChangeEvent) => {
      if (event.status === 'allow') {
        storageService.setItem(LS_KEYS.COOKIES_ACCEPT, 'true');
      }
      // Initialize ads on close cookies pop up
      adsService.initialize();
      // Destroy service instance
      cookiesService.destroy();
    });
}

/**
 * Save browser name in window
 */
function saveBrowserName(window: CustomWindow) {
  const userAgent = navigator.userAgent;
  let browserName: BrowserTypes;

  if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = 'chrome';
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = 'firefox';
  } else if (userAgent.match(/safari/i)) {
    browserName = 'safari';
  } else if (userAgent.match(/opr\//i)) {
    browserName = 'opera';
  } else if (userAgent.match(/edg/i)) {
    browserName = 'edge';
  }
  window.browser = browserName;
}

/**
 * Web initial actions
 */
function doWebActions(window: CustomWindow) {
  if (Capacitor.getPlatform() === 'web') {
    // Save browser name in window
    saveBrowserName(window);
  }
}

/**
 * Device initial actions
 */
async function doDeviceActions() {
  if (Capacitor.getPlatform() !== 'web') {
    const { SplashScreen } = await import('@capacitor/splash-screen');
    const { Keyboard } = await import('@capacitor/keyboard');

    // Hide splash screen on load app
    SplashScreen.hide();
    // Keyboard config for mobile devices
    Keyboard.setAccessoryBarVisible({ isVisible: true });
  }
}