import { inject, Injectable } from '@angular/core';
import { openDB, IDBPDatabase } from '@tempfix/idb';
import { Observable, from, of } from 'rxjs';
import { switchMap, withLatestFrom } from 'rxjs/operators';
import { CacheDB } from './cache.models';
import { CACHE_CONFIG } from './cache.config';
import { Store } from '@ngrx/store';
import { StoreState } from '@store/state/store.state';
import { selectConfigurations } from '@store/selectors/cms.selectors';
import packageJson from 'package.json';

@Injectable({ providedIn: 'root' })
export class CacheService {

  /** IndexedDB instance */
  private db$: Observable<IDBPDatabase<CacheDB>>;
  /** Current version of the application */
  private currentAppVersion = packageJson.version;

  /** Dependencies */
  private store = inject(Store<StoreState>);

  constructor() {
    // Initialize the IndexedDB
    this.initializeDB();
  }

  /**
   * Checks if the given URL is cached based on the cache configuration.
   *
   * @param url - The URL to check.
   * @returns True if the URL is cached, false otherwise.
   */
  public isUrlCached(url: string): boolean {
    return CACHE_CONFIG.some(config => config.url.test(url));
  }

  /**
   * Retrieves the cached response for the given URL.
   *
   * @param url - The URL to retrieve the cached response for.
   * @returns An observable containing the cached response or null if not cached.
   */
  public get(url: string): Observable<unknown> {
    return this.db$.pipe(
      switchMap(db => from(db.get('cache', url))),
      withLatestFrom(this.store.select(selectConfigurations)),
      switchMap(([cached, { frontCacheInvalidationTs }]) => {
        if (cached) {
          const {
            expiration: cachedExpiration,
            response: cachedResponse,
            version: cachedVersion,
          } = cached;

          const todayTs = Date.now();
          // Check if the cache is expired
          const isExpirationValid = (cachedExpiration > todayTs);
          // Check if the cache is expired based on config
          const isConfigExpirationValid =  todayTs > (frontCacheInvalidationTs || 0);
          // Check if the cache version is valid
          const isVersionValid = this.currentAppVersion === cachedVersion;
          // Check if the cache is valid
          const isValid = isExpirationValid  && isConfigExpirationValid && isVersionValid;

          // If the cache is valid, return the response
          if (isValid) {
            this.logCacheHit(url, cachedExpiration, cachedResponse);
            return of(cachedResponse);
          }

          // If the cache is invalid, delete it and return null
          return from(
            this.db$.pipe(switchMap(db => db.delete('cache', url)))
          ).pipe(
            switchMap(() => of(null))
          );
        }
        return of(null);
      })
    );
  }

  /**
   * Caches the response for the given URL.
   *
   * @param url - The URL to cache the response for.
   * @param response - The response to cache.
   */
  public set(url: string, response: unknown): void {
    const cacheConfig = CACHE_CONFIG.find(config => config.url.test(url));
    if (cacheConfig) {
      const expiration = Date.now() + cacheConfig.duration;
      this.db$
        .pipe(
          switchMap(db => from(
            db.put('cache', { expiration, version: this.currentAppVersion, response }, url)
          ))
        )
        .subscribe();
    }
  }

  /**
   * Clears the cache.
   */
  public clear(): void {
    this.db$
      .pipe(switchMap(db => from(db.clear('cache'))))
      .subscribe();
  }

  /**
   * Initializes the IndexedDB.
   */
  private initializeDB(): void {
    this.db$ = from(openDB<CacheDB>('cache-db', 1, {
      upgrade(db) {
        db.createObjectStore('cache');
      }
    }));
  }

  /**
   * Logs a cache hit.
   */
  private logCacheHit(url: string, expiration: number, response: unknown): void {
    console.info(
      '%cServing from cache:', 'color: green; font-weight: bold;',
      url,
      { response, expiration: new Date(expiration) }
    );
  }

}
