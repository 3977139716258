import { Injectable, inject } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, tap, withLatestFrom } from 'rxjs/operators';
import { ScheduleTransformer } from '@core/transformers/schedule.transformer';
import { UserTransformer } from '@core/transformers/user.transformer';
import { AppointmentApi } from '@core/models-api/schedule-api.model';
import { UserApiResponse, LoginApiResponse, UserAccountDataApiResponse } from '@core/models-api/user-api.model';
import { setAppointments } from '@store/actions/schedule.actions';
import { setUser, setUserAccountData } from '@store/actions/user.actions';
import { fetchVehicle, setVehicle, setVehicles } from '@store/actions/vehicles.actions';
import { StoreState } from '@store/state/store.state';
import { LocalNotificationsService } from '@core/services/plugin-services/local-notifications/local-notifications.service';
import { selectValidUserType } from '@store/selectors/user.selectors';
import { VehicleApiResponse } from '@core/models-api/vehicle-api.model';
import { VehiclesTransformer } from '@core/transformers/vehicles.transformer';

@Injectable({ providedIn: 'root' })
export class StoreService {

  /** Dependencies */
  private store = inject(Store<StoreState>);
  private localNotificationsService = inject(LocalNotificationsService);

  /**
   * Set the complete user store
   */
  public setCompleteStoreOperator(params: { shouldRefreshVehicleId?: string } = {}) {
    const { shouldRefreshVehicleId } = params;
    return (source: Observable<UserApiResponse | LoginApiResponse>): Observable<Action> => {
      return source.pipe(
        withLatestFrom(this.store),
        map(([{ user, appointments, myWorkshopsAppointments }, store]) => ({
          user: UserTransformer.userFromApi(user, myWorkshopsAppointments, store),
          appointments: ScheduleTransformer.scheduleAppointmentsFromApi(appointments, store),
          myWorkshopsAppointments: ScheduleTransformer.scheduleAppointmentsFromApi(myWorkshopsAppointments, store),
        })),
        // Set main states
        tap(({ user, appointments, myWorkshopsAppointments }) => {
          // If user has own or shared vehicles set them, otherwise set vehicles
          const ownVehicles = user.ownVehicles || [];
          const sharedVehicles = user.sharedVehicles || [];
          const vehicles = [...ownVehicles, ...sharedVehicles];
          this.store.dispatch(setVehicles({ vehicles }));
          this.store.dispatch(setAppointments({ appointments, myWorkshopsAppointments }));
        }),
        // If workshop needs to update vehicle fetch vehicle
        tap(({ user }) => {
          if (user.accountData?.userType === 'workshop' && shouldRefreshVehicleId) {
            this.store.dispatch(fetchVehicle({ vehicleId: shouldRefreshVehicleId }));
          }
        }),
        // Register user local notifications
        tap(({ user, appointments }) => this.localNotificationsService.registerUserLocalNotifications(user, appointments)),
        map(({ user }) => setUser({ user })),
      );
    };
  }

  /**
   * Set the updated account data store
   */
  public setAccountDataStoreOperator() {
    return (source: Observable<UserAccountDataApiResponse>): Observable<Action> => {
      return source.pipe(
        withLatestFrom(this.store.pipe(selectValidUserType)),
        map(([{ accountData }, userType]) => UserTransformer.accountDataFromApi(accountData, userType)),
        map((accountData) => setUserAccountData({ accountData })),
      );
    };
  }

  /**
   * Set the updated vehicle store
   */
  public setVehicleStoreOperator() {
    return (source: Observable<VehicleApiResponse>): Observable<Action> => {
      return source.pipe(
        withLatestFrom(this.store),
        map(([{ vehicle }, store]) => {
          const vehicleFromApi = VehiclesTransformer.vehicleFromApi(vehicle, store as StoreState);
          return setVehicle({ vehicle: vehicleFromApi });
        }),
      );
    };
  }

  /**
   * Set user or vehicle depending api response model
   */
  public setFilesFoldersStoreOperator() {
    return (source: Observable<UserApiResponse | VehicleApiResponse>): Observable<Action> => {
      return source.pipe(
        withLatestFrom(this.store),
        map(([res, store]) => {
          // Set vehicle store if has vehicle
          if (Object.prototype.hasOwnProperty.call(res, 'vehicle')) {
            return this.setVehicleStore(res as VehicleApiResponse, store);
          }
          // Set user store
          const { user: userApi, myWorkshopsAppointments } = res as UserApiResponse;
          const userFromApi = UserTransformer.userFromApi(userApi, myWorkshopsAppointments as AppointmentApi[], store as StoreState);
          const ownVehicles = userFromApi.ownVehicles || [];
          const sharedVehicles = userFromApi.sharedVehicles || [];
          const vehicles = [...ownVehicles, ...sharedVehicles];
          this.store.dispatch(setVehicles({ vehicles }));
          return setUser({ user: userFromApi });
        }),
      );
    };
  }

  /**
   * Set vehicle store
   */
  private setVehicleStore(res: VehicleApiResponse, store: StoreState) {
    const { vehicle } = res;
    const vehicleFromApi = VehiclesTransformer.vehicleFromApi(vehicle, store);
    return setVehicle({ vehicle: vehicleFromApi });
  }

}
